var render = function () {
  var _vm$invoiceData, _vm$invoiceData2, _vm$invoiceData3, _vm$invoiceData4, _vm$invoiceData4$refe, _vm$invoiceData5, _vm$invoiceData6, _vm$invoiceData6$refe, _vm$invoiceData7, _vm$invoiceData8, _vm$invoiceData8$refe, _vm$invoiceData9, _vm$invoiceData10, _vm$invoiceData10$ref, _vm$invoiceData11, _vm$invoiceData12, _vm$invoiceData13, _vm$invoiceData13$ref, _vm$invoiceData14, _vm$invoiceData15, _vm$invoiceData15$ref, _vm$invoiceData16, _vm$invoiceData17, _vm$invoiceData17$ref, _vm$invoiceData18, _vm$invoiceData19, _vm$invoiceData19$ref, _vm$invoiceData20, _vm$invoiceData21;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    attrs: {
      "body-class": "p-50"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-start gap-1 bg-primary-gradient"
  }, [_c('IAmIcon', {
    staticClass: "p-1 icon-card",
    attrs: {
      "icon": "infoCircleOutline",
      "size": "25"
    }
  }), _c('span', {
    staticClass: "font-medium-3 m-0 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.detail.title')) + " ")])], 1), _c('div', {
    staticClass: "d-flex-between my-50"
  }, [_c('span', {
    staticClass: "text-airline fw-700"
  }, [_vm._v(_vm._s(_vm.$t('invoice.create.detail.invoiceContent')))]), !(_vm.isAdjust || (_vm$invoiceData = _vm.invoiceData) !== null && _vm$invoiceData !== void 0 && _vm$invoiceData.referenceAdjustedInvoice) ? _c('BFormCheckbox', {
    staticClass: "checkbox-custom",
    model: {
      value: _vm.showTicketNumber,
      callback: function callback($$v) {
        _vm.showTicketNumber = $$v;
      },
      expression: "showTicketNumber"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.detail.showTicketNumber')) + " ")]) : _vm._e()], 1), (_vm.isReplace || (_vm$invoiceData2 = _vm.invoiceData) !== null && _vm$invoiceData2 !== void 0 && _vm$invoiceData2.referenceReplacedInvoice) && !_vm.isAdjust ? _c('div', {
    staticClass: "text-primary fw-600 mb-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.topRowContent.REPLACE', {
    templateNumber: (_vm$invoiceData3 = _vm.invoiceData) !== null && _vm$invoiceData3 !== void 0 && _vm$invoiceData3.referenceReplacedInvoice ? (_vm$invoiceData4 = _vm.invoiceData) === null || _vm$invoiceData4 === void 0 ? void 0 : (_vm$invoiceData4$refe = _vm$invoiceData4.referenceReplacedInvoice) === null || _vm$invoiceData4$refe === void 0 ? void 0 : _vm$invoiceData4$refe.templateNumber : _vm.invoiceData.templateNumber,
    symbol: (_vm$invoiceData5 = _vm.invoiceData) !== null && _vm$invoiceData5 !== void 0 && _vm$invoiceData5.referenceReplacedInvoice ? (_vm$invoiceData6 = _vm.invoiceData) === null || _vm$invoiceData6 === void 0 ? void 0 : (_vm$invoiceData6$refe = _vm$invoiceData6.referenceReplacedInvoice) === null || _vm$invoiceData6$refe === void 0 ? void 0 : _vm$invoiceData6$refe.symbol : _vm.invoiceData.symbol,
    invNumber: (_vm$invoiceData7 = _vm.invoiceData) !== null && _vm$invoiceData7 !== void 0 && _vm$invoiceData7.referenceReplacedInvoice ? (_vm$invoiceData8 = _vm.invoiceData) === null || _vm$invoiceData8 === void 0 ? void 0 : (_vm$invoiceData8$refe = _vm$invoiceData8.referenceReplacedInvoice) === null || _vm$invoiceData8$refe === void 0 ? void 0 : _vm$invoiceData8$refe.invNumber : _vm.invoiceData.invNumber,
    createdAt: _vm.convertISODateTime((_vm$invoiceData9 = _vm.invoiceData) !== null && _vm$invoiceData9 !== void 0 && _vm$invoiceData9.referenceReplacedInvoice ? (_vm$invoiceData10 = _vm.invoiceData) === null || _vm$invoiceData10 === void 0 ? void 0 : (_vm$invoiceData10$ref = _vm$invoiceData10.referenceReplacedInvoice) === null || _vm$invoiceData10$ref === void 0 ? void 0 : _vm$invoiceData10$ref.createdAt : _vm.invoiceData.createdAt).date
  })) + " ")]) : _vm._e(), (_vm.isAdjust || (_vm$invoiceData11 = _vm.invoiceData) !== null && _vm$invoiceData11 !== void 0 && _vm$invoiceData11.referenceAdjustedInvoice) && !_vm.isReplace ? _c('div', {
    staticClass: "d-flex flex-column gap-1"
  }, [_c('div', {
    staticClass: "text-primary fw-600 mb-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.topRowContent.ADJUST', {
    templateNumber: (_vm$invoiceData12 = _vm.invoiceData) !== null && _vm$invoiceData12 !== void 0 && _vm$invoiceData12.referenceAdjustedInvoice ? (_vm$invoiceData13 = _vm.invoiceData) === null || _vm$invoiceData13 === void 0 ? void 0 : (_vm$invoiceData13$ref = _vm$invoiceData13.referenceAdjustedInvoice) === null || _vm$invoiceData13$ref === void 0 ? void 0 : _vm$invoiceData13$ref.templateNumber : _vm.invoiceData.templateNumber,
    symbol: (_vm$invoiceData14 = _vm.invoiceData) !== null && _vm$invoiceData14 !== void 0 && _vm$invoiceData14.referenceAdjustedInvoice ? (_vm$invoiceData15 = _vm.invoiceData) === null || _vm$invoiceData15 === void 0 ? void 0 : (_vm$invoiceData15$ref = _vm$invoiceData15.referenceAdjustedInvoice) === null || _vm$invoiceData15$ref === void 0 ? void 0 : _vm$invoiceData15$ref.symbol : _vm.invoiceData.symbol,
    invNumber: (_vm$invoiceData16 = _vm.invoiceData) !== null && _vm$invoiceData16 !== void 0 && _vm$invoiceData16.referenceAdjustedInvoice ? (_vm$invoiceData17 = _vm.invoiceData) === null || _vm$invoiceData17 === void 0 ? void 0 : (_vm$invoiceData17$ref = _vm$invoiceData17.referenceAdjustedInvoice) === null || _vm$invoiceData17$ref === void 0 ? void 0 : _vm$invoiceData17$ref.invNumber : _vm.invoiceData.invNumber,
    createdAt: _vm.convertISODateTime((_vm$invoiceData18 = _vm.invoiceData) !== null && _vm$invoiceData18 !== void 0 && _vm$invoiceData18.referenceAdjustedInvoice ? (_vm$invoiceData19 = _vm.invoiceData) === null || _vm$invoiceData19 === void 0 ? void 0 : (_vm$invoiceData19$ref = _vm$invoiceData19.referenceAdjustedInvoice) === null || _vm$invoiceData19$ref === void 0 ? void 0 : _vm$invoiceData19$ref.createdAt : _vm.invoiceData.createdAt).date
  })) + " ")]), _c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.detail.adjustReason'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "adjust-reason",
            "label-cols-sm": "3"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex gap-1"
              }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.detail.adjustReason')) + " "), _c('IAmIcon', {
                attrs: {
                  "icon": "infoOutline",
                  "size": "16"
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "adjust-reason",
            "value": _vm.invoiceData.adjustmentReason,
            "placeholder": _vm.$t('invoice.create.detail.phAdjustReason'),
            "rows": "2",
            "state": errors[0] ? false : null,
            "disabled": !_vm.isEditing,
            "max-rows": "4"
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
                adjustmentReason: val
              }));
            }
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "adjust-reason",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }], null, false, 2777864403)
  }), ![_vm.ADJUSTMENT_TYPE.INFORMATIONAL_ADJUSTMENT].includes((_vm$invoiceData20 = _vm.invoiceData) === null || _vm$invoiceData20 === void 0 ? void 0 : _vm$invoiceData20.adjustmentType) ? _c('div', {
    staticClass: "d-flex-between my-50"
  }, [_c('BFormCheckbox', {
    staticClass: "checkbox-custom",
    attrs: {
      "checked": _vm.invoiceData.autoCalculate
    },
    on: {
      "change": function change(val) {
        return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
          autoCalculate: val
        }));
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.detail.adjustAutoCalculate')) + " ")]), _c('BFormCheckbox', {
    staticClass: "checkbox-custom",
    model: {
      value: _vm.showTicketNumber,
      callback: function callback($$v) {
        _vm.showTicketNumber = $$v;
      },
      expression: "showTicketNumber"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.detail.showTicketNumber')) + " ")])], 1) : _vm._e()], 1) : _vm._e(), _c('b-table', {
    ref: "refInvoiceListTable",
    class: "position-relative mb-50 ".concat(_vm.isInvalidInvoiceItems ? 'border-danger rounded' : ''),
    style: "max-height: 800px; ".concat(!_vm.isEditing ? 'background-color: #f8f8f8' : ''),
    attrs: {
      "sticky-header": true,
      "items": _vm.selectedTickets,
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('invoice.create.detail.pleaseChooseTickets'),
      "no-border-collapse": "",
      "small": "",
      "bordered": "",
      "tbody-tr-class": {
        'bg-disabled': !_vm.isEditing
      },
      "fields": _vm.tableColumnInvoiceCreateDetail
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnInvoiceCreateDetail, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key,
            staticClass: "text-dark text-nowrap d-flex-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.columns.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "empty",
      fn: function fn(scope) {
        return [_c('b-alert', {
          staticClass: "px-2 py-1 mb-0",
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_vm._v(" " + _vm._s(scope.emptyText) + " ")])];
      }
    }, {
      key: "cell(no)",
      fn: function fn(row) {
        return [row.item.itemType !== _vm.GOODS_SERVICE_TYPES.NOTE ? _c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.selectedTickets.filter(function (i) {
          return i.itemType !== _vm.GOODS_SERVICE_TYPES.NOTE;
        }).findIndex(function (i) {
          return i.id === row.item.id;
        }) + 1) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(name)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.columns.name'),
            "rules": "required",
            "vid": "name-".concat(item.id)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('BFormGroup', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c(item.name.length > 20 && item.itemType === _vm.GOODS_SERVICE_TYPES.NOTE ? 'BFormTextarea' : 'BFormInput', {
                tag: "component",
                class: {
                  'border-transparent': !errors[0],
                  'fw-700': item.itemType === _vm.GOODS_SERVICE_TYPES.NOTE
                },
                staticStyle: {
                  "overflow": "auto"
                },
                attrs: {
                  "id": "name-".concat(item.id),
                  "disabled": !_vm.isEditing,
                  "name": _vm.$t('invoice.columns.name'),
                  "state": errors[0] ? false : null,
                  "placeholder": _vm.$t('invoice.create.detail.phName'),
                  "rows": "2",
                  "max-rows": "6"
                },
                model: {
                  value: item.name,
                  callback: function callback($$v) {
                    _vm.$set(item, "name", $$v);
                  },
                  expression: "item.name"
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "name-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1)];
      }
    }, {
      key: "cell(ticketNumber)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.itemType !== _vm.GOODS_SERVICE_TYPES.NOTE ? _c('div', {
          staticClass: "width-150"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.columns.ticketNumber'),
            "rules": "required|isUniqueTicketNumber:".concat(_vm.selectedTickets.map(function (i) {
              return i === null || i === void 0 ? void 0 : i.ticketNumber;
            })),
            "vid": "ticketNumber-".concat(item.id)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('BFormGroup', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c('BFormInput', {
                class: {
                  'border-transparent': !errors[0]
                },
                attrs: {
                  "id": "ticketNumber-".concat(item.id),
                  "state": errors[0] ? false : null,
                  "disabled": !_vm.isEditing,
                  "formatter": function formatter(value) {
                    return value.trim();
                  },
                  "name": _vm.$t('invoice.columns.ticketNumber')
                },
                on: {
                  "input": function input(val) {
                    return _vm.onTicketNumberChange(val, item);
                  }
                },
                model: {
                  value: item.ticketNumber,
                  callback: function callback($$v) {
                    _vm.$set(item, "ticketNumber", $$v);
                  },
                  expression: "item.ticketNumber"
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "ticketNumber-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(unit)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.itemType !== _vm.GOODS_SERVICE_TYPES.NOTE ? _c('div', [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.columns.unit'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('BFormGroup', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c('BFormInput', {
                class: {
                  'border-transparent': !errors[0]
                },
                attrs: {
                  "id": "unit-".concat(item.id),
                  "disabled": !_vm.isEditing,
                  "state": errors[0] ? false : null,
                  "name": _vm.$t('invoice.columns.unit')
                },
                model: {
                  value: item.unit,
                  callback: function callback($$v) {
                    _vm.$set(item, "unit", $$v);
                  },
                  expression: "item.unit"
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "unit-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(quantity)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.itemType !== _vm.GOODS_SERVICE_TYPES.NOTE ? _c('div', [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.columns.quantity'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('BFormGroup', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c('BFormInput', {
                class: {
                  'border-transparent': !errors[0],
                  'text-right': true
                },
                attrs: {
                  "id": "quantity-".concat(item.id),
                  "state": errors[0] ? false : null,
                  "disabled": !_vm.isEditing,
                  "type": 'number',
                  "name": _vm.$t('invoice.columns.quantity')
                },
                model: {
                  value: item.quantity,
                  callback: function callback($$v) {
                    _vm.$set(item, "quantity", $$v);
                  },
                  expression: "item.quantity"
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "quantity-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(unitPrice)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.itemType !== _vm.GOODS_SERVICE_TYPES.NOTE ? _c('div', {
          staticClass: "width-150"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.columns.unitPrice'),
            "rules": "required|min_value:0",
            "vid": "unitPrice-".concat(item.id)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validations) {
              return [_c('BFormGroup', {
                class: {
                  'is-invalid': validations.errors[0]
                }
              }, [_c('BFormInput', {
                staticClass: "d-none",
                attrs: {
                  "value": item.unitPrice,
                  "disabled": "",
                  "name": _vm.$t('invoice.columns.unitPrice')
                }
              }), _c('IAmInputMoney', {
                attrs: {
                  "id": "unitPrice-".concat(item.id),
                  "value-money": item.unitPrice,
                  "disabled": !_vm.isEditing,
                  "canInputNegative": true,
                  "custom-class": "".concat(!validations.errors[0] ? 'border-transparent' : ''),
                  "state": validations.errors[0] ? false : null,
                  "on-blur": function onBlur(val) {
                    return _vm.handleUpdateInvoiceItem(item, 'unitPrice', val);
                  }
                }
              }), validations.errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "unitPrice-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(validations.errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(vatRate)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.itemType !== _vm.GOODS_SERVICE_TYPES.NOTE ? _c('div', {
          staticClass: "custom-v-select-vat-rate"
        }, [_c('BFormGroup', [_c('v-select', {
          staticClass: "border-transparent per-page-selector",
          attrs: {
            "options": _vm.TAX_TYPES,
            "clearable": false,
            "append-to-body": "",
            "label": "label",
            "disabled": !_vm.isEditing || _vm.isSingleTaxRateType,
            "reduce": function reduce(val) {
              return val.label;
            },
            "calculate-position": _vm.withPopper
          },
          on: {
            "input": function input(val) {
              return _vm.handleUpdateInvoiceItem(item, 'vatRate', _vm.TAX_TYPES_OBJECT[val]);
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(data.value) + " % ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(data.value) + " % ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: item.valueAddedTax,
            callback: function callback($$v) {
              _vm.$set(item, "valueAddedTax", $$v);
            },
            expression: "item.valueAddedTax"
          }
        })], 1)], 1) : _vm._e()];
      }
    }, {
      key: "cell(vatAmount)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [item.itemType !== _vm.GOODS_SERVICE_TYPES.NOTE ? _c('div', {
          staticClass: "width-150"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.columns.vatAmount'),
            "rules": "required|min_value:0"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('BFormGroup', [_c('BFormInput', {
                staticClass: "d-none",
                attrs: {
                  "value": item.vatAmount,
                  "disabled": "",
                  "name": _vm.$t('invoice.columns.vatAmount')
                }
              }), _c('IAmInputMoney', {
                attrs: {
                  "id": "vatAmount-".concat(item.id),
                  "value-money": item.vatAmount,
                  "disabled": !_vm.isEditing,
                  "canInputNegative": true,
                  "custom-class": "".concat(!errors[0] ? 'border-transparent' : ''),
                  "state": errors[0] ? false : null,
                  "name": _vm.$t('invoice.columns.vatAmount'),
                  "on-blur": function onBlur(val) {
                    return _vm.handleUpdateInvoiceItem(item, 'vatAmount', val);
                  }
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "vatAmount-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [item.itemType !== _vm.GOODS_SERVICE_TYPES.NOTE ? _c('div', {
          staticClass: "width-150"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.columns.amount'),
            "rules": "required|min_value:0"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref15) {
              var errors = _ref15.errors;
              return [_c('BFormGroup', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c('BFormInput', {
                staticClass: "d-none",
                attrs: {
                  "value": item.amount,
                  "disabled": "",
                  "name": _vm.$t('invoice.columns.amount')
                }
              }), _c('IAmInputMoney', {
                attrs: {
                  "id": "amount-".concat(item.id),
                  "value-money": item.amount,
                  "disabled": !_vm.isEditing,
                  "canInputNegative": true,
                  "custom-class": "".concat(!errors[0] ? 'border-transparent' : ''),
                  "state": errors[0] ? false : null,
                  "name": _vm.$t('invoice.columns.amount'),
                  "on-blur": function onBlur(val) {
                    return _vm.handleUpdateInvoiceItem(item, 'amount', val);
                  }
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "amount-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(actionNoTitle)",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [item.itemType !== _vm.GOODS_SERVICE_TYPES.NOTE ? _c('div', {
          staticClass: "d-flex-center gap-1"
        }, [_c('BButton', {
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark",
            "disabled": !_vm.isEditing
          },
          on: {
            "click": function click($event) {
              return _vm.removeLineHandle(item);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "trash",
            "size": "18",
            "color": "#A30D11"
          }
        })], 1)], 1) : _vm._e()];
      }
    }], null, true)
  }), _vm.isInvalidInvoiceItems ? _c('div', {
    staticClass: "text-danger px-50 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.msg.invalidInvoiceItems')) + " ")]) : _vm._e(), _vm.isEditing && ![_vm.ADJUSTMENT_TYPE.INFORMATIONAL_ADJUSTMENT].includes((_vm$invoiceData21 = _vm.invoiceData) === null || _vm$invoiceData21 === void 0 ? void 0 : _vm$invoiceData21.adjustmentType) ? _c('div', {
    staticClass: "d-flex-center justify-content-end mb-50 gap-1"
  }, [_c('BButton', {
    staticClass: "d-flex-center gap-1 p-50",
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": _vm.addLineHandle
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "increase",
      "color": "white",
      "size": "18"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.btn.addLine')) + " ")], 1), _c('BButton', {
    staticClass: "d-flex-center gap-1 p-50",
    attrs: {
      "variant": "danger",
      "size": "sm"
    },
    on: {
      "click": _vm.clearAllLine
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "trash",
      "color": "white",
      "size": "18"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.btn.clearAll')) + " ")], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "note-invoice"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.detail.note')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "note-invoice",
      "value": _vm.invoiceData.note,
      "placeholder": _vm.$t('invoice.create.detail.phNote'),
      "rows": "3",
      "disabled": !_vm.isEditing,
      "max-rows": "6"
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
          note: val
        }));
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "d-flex flex-column gap-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('invoice.create.detail.totalGoodsAmount')))])]), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.detail.totalGoodsAmount'),
      "rules": "required|min_value:0",
      "vid": "totalGoodsAmount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('BFormGroup', {
          class: {
            'is-invalid': errors[0]
          }
        }, [_c('BFormInput', {
          staticClass: "d-none",
          attrs: {
            "value": _vm.getTotalGoodsAmount,
            "disabled": "",
            "name": _vm.$t('invoice.create.detail.totalGoodsAmount')
          }
        }), _c('IAmInputMoney', {
          attrs: {
            "id": "totalGoodsAmount",
            "value-money": _vm.getTotalGoodsAmount,
            "disabled": !_vm.isEditing,
            "custom-class": "".concat(!errors[0] ? 'border-transparent bg-transparent' : ''),
            "state": errors[0] ? false : null,
            "name": _vm.$t('invoice.create.detail.totalGoodsAmount'),
            "placeholder": _vm.$t('invoice.create.detail.totalGoodsAmount'),
            "canInputNegative": true,
            "on-blur": function onBlur(val) {
              return _vm.handleUpdateInvoiceTotal(val, 'totalGoodsAmount');
            }
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "totalGoodsAmount",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1), _vm.isSingleTaxRateType ? _c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('invoice.columns.vatRate')))])]), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('BFormGroup', {
    staticClass: "custom-v-select-vat-rate"
  }, [_c('v-select', {
    staticClass: "border-transparent w-100",
    attrs: {
      "value": _vm.invoiceData.valueAddedTax,
      "options": _vm.TAX_TYPES,
      "clearable": false,
      "append-to-body": "",
      "label": "label",
      "disabled": !_vm.isEditing,
      "reduce": function reduce(val) {
        return val.label;
      },
      "calculate-position": _vm.withPopper
    },
    on: {
      "input": function input(val) {
        _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
          valueAddedTax: val
        }));
        _vm.onVatRateChange(val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.value) + " % ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.value) + " % ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 3484493058)
  })], 1)], 1)], 1) : _vm._e(), _c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('invoice.create.detail.collectionFee')))])]), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.detail.collectionFee'),
      "rules": "required|min_value:0",
      "vid": "collectionFee"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('BFormGroup', {
          class: {
            'is-invalid': errors[0]
          }
        }, [_c('BFormInput', {
          staticClass: "d-none",
          attrs: {
            "value": _vm.getCollectionFee,
            "disabled": "",
            "name": _vm.$t('invoice.create.detail.collectionFee')
          }
        }), _c('IAmInputMoney', {
          attrs: {
            "id": "collectionFee",
            "value-money": _vm.getCollectionFee,
            "disabled": !_vm.isEditing,
            "custom-class": "".concat(!errors[0] ? 'border-transparent bg-transparent' : ''),
            "state": errors[0] ? false : null,
            "name": _vm.$t('invoice.create.detail.collectionFee'),
            "placeholder": _vm.$t('invoice.create.detail.collectionFee'),
            "canInputNegative": true,
            "on-blur": function onBlur(val) {
              return _vm.handleUpdateInvoiceTotal(val, 'collectionFee');
            }
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "collectionFee",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1), _c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('invoice.create.detail.totalAmount')))])]), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.detail.totalAmount'),
      "rules": "required|min_value:1",
      "vid": "totalAmount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('BFormGroup', {
          class: {
            'is-invalid': errors[0]
          }
        }, [_c('BFormInput', {
          staticClass: "d-none",
          attrs: {
            "value": _vm.getTotalAmount,
            "disabled": "",
            "name": _vm.$t('invoice.create.detail.totalAmount')
          }
        }), _c('IAmInputMoney', {
          attrs: {
            "id": "totalAmount",
            "value-money": _vm.getTotalAmount,
            "disabled": !_vm.isEditing,
            "custom-class": "".concat(!errors[0] ? 'border-transparent bg-transparent' : ''),
            "state": errors[0] ? false : null,
            "name": _vm.$t('invoice.create.detail.totalAmount'),
            "placeholder": _vm.$t('invoice.create.detail.totalAmount'),
            "canInputNegative": true,
            "on-blur": function onBlur(val) {
              return _vm.handleUpdateInvoiceTotal(val, 'totalAmount');
            }
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "totalAmount",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }